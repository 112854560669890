import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

$(function () {
  gsap.to('#home-hero', {
    scrollTrigger: {
      trigger: '#home-hero',
      start: 'top top',
      end: 'bottom 75svh',
      scrub: true,
    },
    opacity: 0,
    filter: `blur(30px)`,
  })

  gsap.to('#home-hero-background', {
    scrollTrigger: {
      trigger: '#home-hero-background',
      start: 'top top',
      end: 'bottom 50svh',
      scrub: true,
    },
    opacity: 0,
    filter: `blur(30px)`,
    scale: 1.125,
  })

  boxIn('.hero-event-type.row-1')
  boxIn('.hero-event-type.row-2')

  $('#join-waitlist').on('click', function () {
    const offset = $('#waitlist-start')?.offset()

    if (!offset) {
      return
    }

    $('html, body').animate({ scrollTop: offset.top - 80 }, 750)
  })

  function boxIn(trigger: string) {
    gsap.from(trigger, {
      scrollTrigger: {
        trigger,
        start: 'top 90%',
        end: 'center 75%',
        toggleActions: 'play none none none',
        scrub: true,
      },
      opacity: 0,
      y: '60px',
      ease: 'power2.out',
      scale: 0.95,
      stagger: 0.125,
    })
  }
})
